.App {
  display: flex;
  height: 100vh;
}

.left-panel, .right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
}

.search-bar {
  margin-bottom: 20px;
  width: 90%;
}

.slider {
  width: 90%;
  margin-top: 10px;
}
